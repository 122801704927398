import moment from "moment";
import { INotaFiscal, IResponseAgendamento, IResponseDatasPercentual, ITicket } from "../../../types/Agendamentos/agendamento.d";
import { convertNumberToString, convertStringToNumber } from "../../../utils/strings";
import { formatDateToBRLformatDDMM } from "../../../utils/date";

export const mapResponseAgendamentoToTicket: (prop: IResponseAgendamento) => ITicket = (response) => {
    return {
        ...response,
        quantidadeVeiculos: convertStringToNumber(response.quantidadeVeiculos),
        telefone: convertNumberToString(response.telefone),
        data: moment(response.data).format("YYYY-MM-DD"),
        dataDesejada: moment(response.dataDesejada).format("YYYY-MM-DD"),
        notas: response?.notas?.map((nota) => {
            return {
                ...nota,
                chave: nota?.chave,
                notaFiscal: nota?.notaFiscal,
                quantidadeSku: nota?.quantidadeSku,
                volume: nota?.volume,
                motivoBloqueio: nota?.motivoBloqueio ? nota?.motivoBloqueio : null,
            } as INotaFiscal
        }),
    } as ITicket
}

export const mapMetricsToGraphic = (metricas: IResponseDatasPercentual[]) => {
    return metricas?.map((metrica: IResponseDatasPercentual) => ({
        ...metrica,
        data: formatDateToBRLformatDDMM(metrica.data),
    }))
};
